<template>
  <div class="PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('recharge.default[0]')"
            left-arrow
            @click-left="$router.go(-1)"
    />
    <div class="ScrollBox">
      <van-cell
              size="large"
              :title="$t('recharge.default[0]')"
              center
              to="/user/mixrecharge"
              is-link
      />
<!--      <van-cell-->
<!--              size="large"-->
<!--              :title="$t('recharge.default[1]')"-->
<!--              center-->
<!--              to="/user/prerecharge"-->
<!--              is-link-->
<!--      />-->
    </div>
  </div>
</template>

<script>
  import $ from 'jquery'
  import QRCode from "qrcodejs2"
  import Html2Canvas from "html2canvas"
export default {
  name: 'Recharge',
  components: {

  },
  props: ['rechargeId'],
  data() {
    return {


    }
  },
  computed: {

  },
  watch: {
    $route(){

    },
  },
  created() {
    this.$parent.navBarTitle = ''
  },
  mounted() {

  },
  activated() {

  },
  destroyed() {

  },
  methods: {
    gobindcoin() {
      this.$router.push("/user/bankCard");

    }
  }
}
</script>
<style scoped>
  .PageBox {

  }
  .ScrollBox {

  }
  .van-cell {
    padding-left: 10px;
  }
</style>
